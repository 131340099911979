import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NewsApiService {
  
  readonly apiURL = environment.apiURL;
  constructor(private http:HttpClient) { }

  // Initial Article
  initArticles(){
    return this.http.get(this.apiURL + 'headlines/en');
  }

  // Articles with all filter
  catArticles(lan: String, cat: String){
    return this.http.get(this.apiURL + 'headlines/'+lan+'/'+cat);
  }

  // Articles with all filter
  allArticles(lan: String, country: String, cat: String){
    return this.http.get(this.apiURL + 'headlines/'+lan+'/'+country+'/'+cat);
  }

  // Articles with country filter
  conArticles(lan: String, country: String){
    return this.http.get(this.apiURL + 'headlines/'+lan+'/'+country);
  }

  // Search articles with query
  searchArticles(q: string, lan: string){
    return this.http.get(this.apiURL + 'everything/'+q+'/'+lan);
  }
  
  // Get articles from source
  sourceArticles(source: string){
    return this.http.get(this.apiURL + 'sources/'+source);
  }

  // Global news with language
  globalArticles(lan: string){
    return this.http.get(this.apiURL + 'headlines/'+lan);
  }

}
